import React from 'react'
import {Form, Button, ButtonToolbar, Row} from 'react-bootstrap'

const OnAdd = ({onClick}) => {
    return (
        <th style={{ width:"auto" }}>
        <ButtonToolbar style={{ display: 'flex', justifyContent: 'right' }}>
        <Button variant='primary' onClick={onClick} alt="Add"
        ><i className="fa fa-plus"></i></Button>
        </ButtonToolbar>
    </th>
    )
}

const OnUpdate = ({onEdit, onUpdate, onCancel, onDelete, onAdd}) => {
    const istyle={
        display: 'flex', justifyContent: 'center'
    }

    return (
    <Row style={{ margin:'0', justifyContent: 'right', alignItems: 'center', position: 'relative', padding:'0.2rem' }}>
        <ButtonToolbar>

{ onAdd ?
        <Button variant='primary' alt="Add"
        onClick={onAdd} 
        ><i className="fa fa-plus" style={istyle}></i></Button>
: null}

{ onEdit ?
        <Button variant="info" alt="Edit" style={{ width: '2rem', display: 'block' }}
        onClick={onEdit}
        ><i className="fa fa-edit" style={istyle}></i></Button>
: null }

{ onCancel ?
            <Button className="mr-2" variant="secondary" style={{ width: '2rem', display: 'block' }} 
            onClick={onCancel}
            ><i className="fa fa-close" style={istyle}></i></Button>
: null }

{ onUpdate ?
            <Button className="mr-2" variant="success" style={{ width: '2rem', display: 'block' }}
            onClick={onUpdate}
            ><i className="fa fa-save" style={istyle}></i></Button>
:null}

{ onDelete ?            
    <Button className="mr-2" variant="danger" style={{ width: '2rem', display: 'block' }}
            onClick={onDelete}
            ><i className="fa fa-trash" style={istyle}></i></Button>

: null }
        </ButtonToolbar>
    </Row>
    )
}

const OnCRUD = ({onInfo, onEdit, onDel, onAdd}) => {

    //let color="inherit"

    return (
    <td style={{ width: "auto"  }}>
        <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', alignItems:"center" }}>
            
            {onInfo ?
                <Button variant="outline-secondary" alt="Info" style={{ width: "2rem"  }}
                onClick={onInfo}
                ><i className="fa fa-info"></i></Button> 
            : null }

            {onEdit ?
                <Button variant="outline-info" alt="Edit" style={{ width: "2rem" }}
                onClick={onEdit}
                ><i className="fa fa-edit" style={{ display: 'flex', justifyContent: 'center' }}></i></Button>
            : null }

            {onAdd ?
                <Button variant='outline-primary' alt="Add" style={{ width: "2rem"  }}
                onClick={onAdd}
                ><i className="fa fa-plus"></i></Button>
            : null }

            {onDel ?
                <Button variant="outline-danger" alt="Delete" style={{ width: "2rem", alignItems:"center"  }}
                onClick={onDel}
                ><i className="fa fa-trash"></i></Button>
            : null }

        </ButtonToolbar>
    </td>
    )
}

const OnSave = ({mode, label}) => {
    return (
        (mode ?? 1) > 0 
        ?
            <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex"}}>
                <Button variant="success" type="submit" style={{width:"auto", fontSize:"1.2rem", margin:"2rem"}}>
                <i className="fa fa-save"></i> {label ?? " حفظ"}    
                </Button>
            </Form.Group>
        :   null
    )
}

export {OnAdd, OnSave, OnCRUD, OnUpdate}
