import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export default class SectionControl extends Component{
    constructor(props){
        super(props)
        this.state = {Items:[], loading:false}
    }
 

    componentDidMount(){
        this.setState({loading:true})
        fetch(this.props.global.apiUrl+'/setting/sections', {
            headers: this.props.global.headers
        })
        .then(response => response.json())
        .then(data => {
            this.setState({Items:data, loading:false})
        })
        .catch(function (error) {
            console.log(error)
        })
    }

    render() {
 
        return (
            this.state.loading ? <div>Items is loading...</div> :
            <Form.Group className='form-row' style={{marginBottom:'1rem'}}>
                
            <Form.Label className='col-sm-3' style={{ display: 'flex', justifyContent: 'flex-begin', fontSize:'1.2rem' }}>{this.props.label} :
            </Form.Label>
            {this.props.readOnly 
            ?
            <Form.Control className='col-sm-6' as="select" name={this.props.name} defaultValue={this.props.id} onChange={this.props.onChange} readOnly={this.props.readOnly} >
                {
                    this.state.Items.map(item => (
                        item.Id===this.props.id ?
                        <option key={item.Id} value={item.Id}>{`${item.Name} (${item.Id})`}</option>
                        : null
                    ))
                }
            </Form.Control>
            :
            <Form.Control className='col-sm-6' as="select" name={this.props.name} onChange={this.props.onChange} defaultValue={this.props.id} readOnly={this.props.readOnly} >
                {
                    this.state.Items.map(item => 
                    <option key={item.Id} value={item.Id}>{`${item.Name} (${item.Id})`}</option>
                    
                )}
            </Form.Control>
            }
        
         </Form.Group>          
            
        )      
    }
}

/*
            <input type="text" class="form-control" id={this.props.id} 
            name={this.props.name} 
            defaultValue={this.props.name} 
            readOnly="true" 
            />
*/