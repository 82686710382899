import React from 'react'
import {Modal,Row,Col,Form} from 'react-bootstrap'
import SectionControl from './SectionControl'
import {ToNotify} from '../Shared/ViewControl'
import HeaderControl from '../Shared/HeaderControl'
import FooterControl from '../Shared/FooterControl'
import SaveControl from '../Shared/SaveControl'

export class NoteModal extends React.Component {
    constructor(props){
        super(props)

        this.state = {snackbaropen:false, snackbarmsg:'', Item:null, loading:false}
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        if(this.props.show) {
            if(this.props.id>0) {
                this.setState({loading:true})
                this.props.global.GetItem(this.props.entity,this.props.id)
                .then((data) =>  {
                        this.setState({Item:data, loading:false})
                        //console.log('ItemModal', this.state.Item); //alert(Item.Position)
                    }
                )
                .catch(error => console.log({ error }))
            }
        }
    }

    snackbarClose = (event) => {
        this.setState({snackbaropen:false})
        this.props.onHide()
    }

    handleSubmit(event){
        event.preventDefault();

        let newData = JSON.stringify({
            Id:(this.props.mode===2)?0:this.state.Item.Id,
            AccountId:1,
            SectionId:event.target.Section.value,
            Text:event.target.Text.value
        })

        fetch(`${this.props.global.apiUrl+'/'+this.props.entity}`,{
            method:(this.props.mode===2)?'POST':'PUT',
headers:this.props.global.headers,
body: newData
})
.then(response => response.json())
        .then(
            (result)=>{
                this.setState({snackbaropen:true, snackbarmsg:result.message})
            },
            (error)=>{
                this.setState({snackbaropen:true, snackbarmsg:error.message})
            }
        )
    }


    
    render() {
        let { Item } = this.state; 
        return(
            <div className="container">

<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>
        <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            centered
        >
<HeaderControl {...this.props} />

            <Modal.Body>

 <Row>
     <Col sm={10} >
     {
            (this.state.loading || (Item===null && this.props.mode===1)) ?
             <div><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i> loading...</div> :
         <Form class="form-horizontal" onSubmit={this.handleSubmit}>

<SectionControl name='SectionId' request={this.props.global.apiUrl+'/setting/sections'} headers={this.props.global.headers} id={Item ? Item.SectionId:1} readOnly={this.props.mode===0} label='Section'/> 

<div class="form-group">
  <label for="comment">Text:</label>
  <textarea class="form-control" rows="3" name="Text" defaultValue={Item ? Item.Text:''}></textarea>
</div>

<SaveControl {...this.props} />

         </Form>
         }
     </Col>
 </Row>

            </Modal.Body>

            <FooterControl {...this.props} />
            
          </Modal>
</div>  
        )
    }
}
