import React,{Component} from 'react'
import {Table} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import {OnUpdate} from '../Shared/ActionControl'
import NoteControl from './NoteControl'
import { toDate } from '../Shared/Formating'
export class Note extends Component{

    constructor (props){
        super(props)
        this.state = { notify:{open:false, message:''}, itemId:0, sections:""}
        this.entity='note'
    }

    componentDidMount(){
        this.props.LoadNotes()
    }

    componentDidUpdate(){
        if(this.state.sections!==this.props.sections) {
            //console.log('componentDidUpdate',this.props.sections)
            this.props.LoadNotes()
            this.setState({sections:this.props.sections})
        }
    }
    

    Add() {
        let ss = this.props.sections.split('-',)
        //console.log("Add note : ",this.props.sections, ss)

        if(ss && ss.length>1) {
            fetch(`${this.props.global.apiUrl+'/'+this.entity}`,{
                method:'POST',
                headers:this.props.global.headers,
                body:JSON.stringify({
                    Text:'New',
                    SectionId:ss[1]
                })
            })
            .then(response => response.json())
            .then(
                
                (result)=>{
                    //console.log('AddNote result ?', result)
                    this.setState({notify:{open:true, message:result.message}})

                    if(result?.message && result.message.endsWith("!"))
                        this.props.LoadNotes()
                },
                (error)=>{
                    //console.log('AddNote error ?', error)
                    this.setState({notify:{open:true, message:error.message}})
                }
            )
        }
        else
            this.setState({notify:{open:true, message:'You have to select a section ...'}})
    }

    onNote(id, editing=false) {
        if(id>0) {
            this.state ?? this.setState({itemId:id})
            this.props.onNote(id, editing) 
        }
    }
    
    render() {

        return (
            <div className="Notes" size="lg">

<ToNotify 
message={this.state.notify.message}
onClose={()=>{this.setState({notify:{open:false, message:''}})}}
/>                
<OnUpdate onAdd={()=> this.Add()} />
<Table>

<tbody>
    {
        this.props.notes.map(item=>
            <tr key={item.Id}>

<NoteControl id={item.Id} section={item.SectionId} value={item.Text} date={toDate(item.Updated)} onClick={()=> this.onNote(item.Id)} 
    style={this.props.sectionStyle(item.SectionId)} 
    />

            </tr>
            )
    }
</tbody>
</Table>

</div>
        )
    }
}

export default Note;