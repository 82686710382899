import React from 'react'
import Section from './Note/Section'
import {Note} from './Note/Note'
import { sectionStyle } from './Shared/Formating'
import {meSplitter} from './Shared/meGlobal'
import NoteControl from './Shared/NoteControl'

export class Home extends React.Component {

  constructor(props){
    super(props)
    this.state = {noteId:0, sections:"-1-", notes:[], editing:false}
    this.entity='note'
    this.splitter = new meSplitter('Side','Home')
  }

  componentDidMount() {
    this.splitter.selectorAll() 
  }

  componentWillUnmount() {
    this.setState({notes:null, sections:""})        
    //document.removeEventListener("click", this.closeMenu);
  }

  onNote = (id, editing) => {
    this.setState({noteId:id, editing:editing})
    //  if(editing) alert("Home.onNote")
  }

  onSections = (sections) => {
    this.setState({sections:sections})
  }

  LoadNotes=()=> {
    if(this.entity) {
      //console.log('LoadNotes for : ',this.state.sections, this.props.search)
      this.setState({noteId:0, editing:false})

      let body= JSON.stringify({
          sections: this.state.sections,
          search:this.props.search
      })
      this.props.global.GET(this.entity+"/sections", body)
      .then(data => this.setState({notes:data}))
      .catch(error => console.log({ error }))
    }
  }


  render() {
        return (

 this.props.global.connected() ?
    <div className="Home" id="HomeContainer" 
    onMouseDown={(e)=>{this.splitter.mouseDown(e)}} 
    onMouseMove={(e)=>{this.splitter.mouseMove(e)}} 
    onMouseUp={(e)=>{this.splitter.mouseUp(e)}}
    >
    <div className="side vside" id="SideSection" style={{width: '20%'}}>
    <Section global={this.props.global} Sections={this.onSections} sectionStyle={sectionStyle} />
    </div>
    <div className="side vside" id="SideNote" style={{width: '50%', overflow: 'hidden'}}>
    <Note global={this.props.global} onNote={this.onNote} LoadNotes={this.LoadNotes} 
          sections={this.state.sections} notes={this.state.notes} sectionStyle={sectionStyle} search={this.props.search} />
    </div>
    <div className="side vside" id="SideDetail" style={{width: '30%'}}>
        <NoteControl global={this.props.global} element={this.state.noteId} 
          onElement={this.onNote} onLoad={this.LoadNotes} editing={this.state.editing} />
    </div>
</div>

: <div>LogIn</div> 
        )
    }
}