import React,{useEffect, useState} from 'react'
import {Table, Col, Row} from 'react-bootstrap'
import {ToNotify} from '../Shared/ViewControl'
import ReactQuill from 'react-quill'
import {OnUpdate} from '../Shared/ActionControl'
import { toDate } from '../Shared/Formating'

const Comment = ({global, note, sectionStyle}) => {
    const entity='comment'
    const apiUrl = `${global.apiUrl}/${entity}/`


    const [items, setItems] = useState([]);
    const [selected, Selected] = useState(0);
    const [editing, Editing] = useState({Id:0, Text:''});
    const [clicked, Clicked] = useState(0);
    const [loaded, Loaded] = useState(false);
    const [notify, Notify] = useState({message:''})
    
    useEffect(() => {
        if(note>0) {
            Selected(0)
            Editing({Id:0,Text:''})
            //console.log('get comments: ', `${apiUrl}${note}`)
            global.GET(`${entity}/${note}`)
            .then(data => {
                setItems(data)
                Loaded(true)
            })
            .catch(error => console.log({ error }))
        }
    }, [loaded, note, apiUrl])

    const Add = () => {
        //console.log('add comment: ', `${apiUrl} : ${note}`)
        fetch(`${apiUrl}`,{
            method:'POST',
            headers:global.headers,
            body:JSON.stringify({
                NoteId: note,
                Text:'New '
            })
        })
        .then(response => response.json())
        .then(
            (result)=>{
                Notify({message:result.message})
                Loaded(false)
            },
            (error)=>{
                Notify({message:error.message})
            }
        )
    }

    function Update(id) {
        if(editing.Id>0 && id>0) {
            fetch(`${apiUrl}`,{
                method:'PUT',
                headers:global.headers,
                body:JSON.stringify({
                    ...editing
                })
            })
            .then(            
                (result)=>{
                Notify({message:result.message})
                Loaded(false)
            },
            (error)=>{
                Notify({message:error.message})
            })
        }
    }

    function Delete(id) {
        if(editing.Id>0 && id>0) {
            global.DELETE(entity, id)
            .then(()=> {
                Loaded(false)
            })
        }
    }

    useEffect(()=>{

        if(selected===0)
            Editing({Id:0,Text:''})

        if(selected===clicked) {
            if(editing===0)
                Selected(0)

        }
        else
            Selected(clicked)    
            
        //let message=`useEffect : Clicked ${clicked} -> Selected ${selected} -> Editing ${editing.Id}`;    
        //console.log(message)

    },[clicked,editing.Text])

    function onEdit(id, text) {
        Editing({Id:id, Text:text})
    }

    function onCancel(id) {
        Editing({Id:0,Text:''})
        Selected(0)        
        Clicked(0)
        //let message=`onCancel (${id}) : Clicked ${clicked} -> Selected ${selected}`;    alert(message)
    }

    const onChange = (text) => {
        if(editing.Text!==text) {
            Editing({Id:editing.Id, Text:text})
            let message=`onChange ${editing.Text}` ;    console.log(message)
        }
    }

    const onSelect = (id) => {        
        Clicked(id)
        //if(id===clicked && selected===clicked) Clicked(0)
        
        let message= `onSelect (${id}) : Clicked ${clicked} -> Selected ${selected} -> Editing ${editing.Id}`; console.log(message)
    }

    const onClean = (value) => {
        let doc = new DOMParser().parseFromString(value, 'text/html') // !
        return doc.body.textContent || ""
    }

        return (
            <div className="mt-0 d-flex justify-content-left">
                

<ToNotify 
message={notify.message}
onClose={()=>Notify({message:''})}
/>                

{ loaded 
?
<div className="Comments" size="lg" style={{width:'100%'}}> 

<OnUpdate onAdd={()=> Add()} />
<Table>
<tbody>
    {
        items.map(item=>
            <tr key={item.Id}>

<td key={item.id} value={item.Text} style={{border:0, margin:0, padding:'0.2rem'}}> 

        <Col  onClick={()=>onSelect(item.Id)} style={sectionStyle(item.Note.section,0,0.8)}>

            <Row style={{ justifyContent: 'right', fontSize: '0.7rem', color:'cyan', paddingRight:'1rem' }}>{toDate(item.Updated)}</Row>
            
            { selected===item.Id 
            ?
            <Row style={{ justifyContent: 'center', fontSize: '1.2rem' }}>
                { editing.Id===item.Id 
                ?
                <Col>
                    <Row style={{ color:'blue', height:'auto', overflowY: 'scroll', fontWeight:'bold', opacity:'1', backgroundColor:'white' }}>
<ReactQuill theme="snow" value={editing.Text} onChange={onChange} style={{width:'100%', height:'auto', margin:'0.2rem'}} />
</Row>
                    <OnUpdate onUpdate={()=> Update(item.Id)} onCancel={()=>onCancel(item.Id)} onDelete={()=>Delete(item.Id)} />
                </Col>
                
                : 
                <Col>
                    <Row style={{ marginLeft: '0.5rem', color:'white' }}>{onClean(item.Text)}</Row>
                    <OnUpdate onEdit={()=> onEdit(item.Id, item.Text)} />                    
                </Col>
                }
            </Row>
            :
                <Row style={{ marginLeft: '0.5rem', fontSize: '1rem', color:'#cccccc' }}>{onClean(item.Text)}</Row>
            }
        </Col> 
        </td>

            </tr>
            )
    }
    </tbody>
</Table>
</div>
:
<h5>Comments loading...</h5>
}
</div>
        )
    
}

export default Comment;
