import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import {Navbar, Container} from 'react-bootstrap'
import NoteModal from './Note/NoteModal'

export class Navigation extends Component {

    render(){

        return (
            
            <Container>
{ this.props.global.connected() ?
            <Navbar>
                <Navbar.Collapse id="basic-navbar-nav" >
                    <NavLink className="d-inline p-3 navbar-custom" to="/" onClick={()=>this.props.global.AddNote()}>New Note</NavLink>
                    <NavLink className="d-inline p-3 navbar-custom" to="/detail">Detail</NavLink>
                </Navbar.Collapse>

            </Navbar>
: null }

            </Container>

        )
    }
}

/**
 * onClick={this.props.global.addNode()}
 * 
 */