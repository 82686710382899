import React, {useState} from 'react'
import {Route, Routes, Navigate, BrowserRouter} from 'react-router-dom'
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Global from './components/Global'
import {Home} from './components/Home'


const global = new Global()

function App() {

  const app = 'Note'
  const [search, Search]  = useState("")
  
  //global.Delay(1000)

  return (

    <BrowserRouter>

    <Header global={global} onSearch={Search} />

    <Routes>    
      <Route path='/' element={ <Home global={global} search={search} />} exact />
    </Routes>
      
    <Footer/>

    </BrowserRouter>
  )
}

export default App;
