import React from 'react'
import {Row, Col} from 'react-bootstrap'
import ReactQuill from 'react-quill'


const NoteControl = ({id, value, date, onClick, style}) => {

    let fstyle= {...style, opacity:1, border:0}

    //let color=sectionStyle(section)
    //alert(color)
    return (
        <td key={id} value={value} style={{border:0, padding:'0.3rem'}}> 
            <Col style={fstyle}>
            <Row style={{margin:'0'}} onClick={onClick}>
            <Col sm={6} style={{fontSize: '1rem', fontWeight: 'bold', color:'silver' }} >{id}</Col>
            <Col sm={6} style={{fontSize: '0.7rem', textAlign:'right', color:'cyan'}}>{date}</Col>
            </Row>
            <Row style={{ justifyContent: 'left', fontSize: '1rem', color:'white' }} onClick={onClick}>
                <ReactQuill theme="bubble" modules={{toolbar:null}} value={value} readOnly={true} style={{width:'100%', height:'auto'}} />
            </Row>
          </Col>
        </td>
    )
}

export default NoteControl


/*
<Row style={{ justifyContent: 'left', fontSize: '1.2rem', color:'white' }}>
{cleanNote.substring(0,512)}
</Row>

<Row style={{ justifyContent: 'right' }}><OnUpdate onEdit={()=> onNote(id, true)} /></Row>

*/
