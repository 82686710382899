/* MeR 
First formating 221004
*/

const toDate = (value, time=true) => {
        const meDate = (value) ? new Date(value) : new Date() ;
        let dt = meDate.toISOString()?.split('T')
        //console.log('meDate', meDate.toISOString())
        return (time)?dt[0]?.substring(2)+" "+dt[1]?.substring(0,5):dt[0]?.substring(2);
}

/* 221001
https://www.w3schools.com/colors/colors_crayola.asp
B'dazzled Blue  #2E5894
Big Dip O' Ruby #9C2542
Metallic Seaweed    #0A7E8C


Steel Blue	#0081AB

*/
const sectionStyle = (section, border=1, opacity=0.7) => {
    //alert(section)
    let style ={
        opacity: `${opacity}`,
        border: `${border}px solid`,
        backgroundColor:'black',
    }
    
    switch(section){
        case 1: 
        style.backgroundColor="#2E5894"
        break

      case 2 : 
        style.backgroundColor="#9C2542"
        break
        
      case 3: 
        style.backgroundColor="#0A7E8C"
        break
      
      case 4: 
        style.backgroundColor="#85754E"
        break

      case 5: 
        style.backgroundColor="#4A646C"
        break

      case 6: 
        style.backgroundColor="#58427C"
        break

      case 7: 
        style.backgroundColor="#757575"
        break

      case 8: 
        style.backgroundColor="#0081AB"
        break

      default:
        style.backgroundColor="#32527b"
        break
    }
    //alert(color)
    return style
}

const onClean = (value) => {
  //let cleanNote = note.replace(/<\/?[^>]+(>|$)/g,"")

  let doc = new DOMParser().parseFromString(value, 'text/html') // !
  return doc.body.textContent || ""
}


export {toDate, sectionStyle, onClean}