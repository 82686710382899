import React,{Component} from 'react'
import {Row,Col} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import SectionControl from './SectionControl'
import {OnUpdate} from '../Shared/ActionControl'

export class Detail extends Component{

    constructor (props){
        super(props)
        this.state = { id:0, noteText:"", sectionId:1, message:"", current:null}
        this.entity='note'
    }

    componentDidUpdate(){
        if(this.state.id!==this.props.id) {
            //console.log(`NoteDetail.componentDidUpdate editing ${this.props.editing}`)
            this.Load()
        }
    }

    onChange = (e) => {
        //  console.log("onChange", e, this.state.current)
        this.setState({noteText: e})    
    }

    onSection = (e) => {
        if(this.props.editing) {
            this.setState({sectionId: e.target?.value ?? 1, message:'Section updated !'})
        }
    }
    
    Load() {        
        if(this.props.id>0) {            
            this.props.global.GetItem(this.entity, this.props.id)
            .then(data => this.setState({id:data.Id, noteText:data.Text, sectionId:data.SectionId, current:data}))
            .catch(error => console.log({ error }))
        }
    }    
    
    Delete(id) {
        if(this.props.editing) {
            this.props.global.DELETE(this.entity, id)
            .then(()=> {
                this.props.LoadNotes()
                this.setState({id:0, noteText:''})
            })
            .catch(error => console.log({ error }))
        }
    }

    Cancel() {
        if(this.props.editing) {
            this.props.LoadNotes()
            this.Load()
        }
    }

    Update(id) {
        if(id>0) {
            fetch(`${this.props.global.apiUrl+'/'+this.entity}`,{
                method:'PUT',
                headers:this.props.global.headers,
                body:JSON.stringify({
                Id: id,
                SectionId: this.state.sectionId,
                Text:this.state.noteText
                })
            })
            .then(()=> {
                this.props.LoadNotes()
                this.Load()
            })
            .catch(error => console.log({ error }))
        }
    }

    render() {

        return (
    <>


{ this.state.id>0 ? 
<Col className="lg" style={{margin:'0', padding:"1rem"}} >
<SectionControl name='SectionId' global={this.props.global} id={this.state.sectionId} onChange={this.onSection} readOnly={!this.props.editing} label={this.props.id} /> 

<Row style={{ justifyContent: 'left', fontSize: '1.2rem', backgroundColor:'#eeeeee', overflowY: 'scroll' }}>
<ReactQuill theme="snow"
style={this.props.editing ? {width:'100%', height:'auto'} :  {width:'100%', height:'auto', backgroundColor:'#cccccc'}}
value={this.state.noteText} onChange={this.onChange} 
readOnly={!this.props.editing} />
</Row>

{ this.props.editing ?
    <OnUpdate onUpdate={()=> this.Update(this.props.id)} onCancel={()=>this.Cancel()} onDelete={()=>this.Delete(this.props.id)} />
: 
    <OnUpdate onEdit={()=> this.props.onNote(this.props.id, true)} />
}

    </Col>
: <h3>Note Detail ...</h3> }

</>


        )
    }
}

