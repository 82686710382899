import React, { useState, useEffect } from "react"
import {Button,Form} from 'react-bootstrap'

const SignEmail = ({signApi, onMessage}) => {

  const [starting, setStart] = useState('Start'); 
  const [valid, setValid] = useState(false);
  const [mode, Mode] = useState(0);

  useEffect(() => {
    if(valid) {        
      signApi.isDataReady(1)
    }
  }, [valid, mode])  
 
  function onSubmit(event) {
      signApi.POST(Mode)
      .then(()=>{
        if(signApi.data.mode>0) {
          document.cookie = `meEmail=${signApi.data.email}`
          onMessage(signApi.message)
        }
      })
  }


  const onChange = (e) => {
    setValid(false)
    setStart('Invalid Email')
    if(signApi.isEmailReady(e.target.value)) {
          setValid(true)
          setStart('Next')
    }
  }

const onChangeCode = (e) => {
  signApi.data.code=e.target.value

  setValid(false)
  setStart('Invalid Code')
  if(signApi.data.code.length>4) {
        setValid(true)
        setStart('Sign In')
  }  
}


  return (
    <div className="outlet">
      
      { signApi.data.mode<3 ?
      <Form style={{width:"100%"}}>
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>  
                      
           { signApi.data.mode===1 ?
               <Form.Control key={"SignEmail"}
               style={{display: "block", width:"70%", margin:"1rem",fontSize:"1.2rem", color:"darkblue"}}
               type="email"
               name="Email"
               required
               placeholder="Email"
               defaultValue="" // mohamed@errais.net, rais@digitalservices.ma
               onChange={onChange}
               />
              :
              <Form.Control key={"SignCode"}
              style={{display: "block", width:"70%", margin:"1rem",fontSize:"1.2rem", color:"darkred"}}
              name="Code"
              required
              placeholder="Code5"
              defaultValue=""
              onChange={onChangeCode}
              />
           }
           </Form.Group>

           <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>

           <Button onClick={() => onSubmit()} size="lg" style={ valid ? {color:'white'} : {width:"33%",backgroundColor:'orange',color:'yellow', pointerEvents:'none', cursor:'not-allowed'}}>
                {starting}</Button>

           </Form.Group>
      </Form>
      : 
      null
      }
    </div>

  )
}
export default SignEmail