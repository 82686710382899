import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5rem 0;  /*top and bottom 0.5rem */

    /*@media screen and (min-width: 1180px) {        width: 50%;    }*/
`;

const Section = styled.button`
    display: block;
    width: 95%;
    border: 5px solid;
    border-radius: 15px;
    padding: 10px;
    margin: 2%;
    text-decoration: none;
    color: rgba(0,158,213,255);
    background-color: #32527b;
    border-color: '#BCC6CC'; /* Metallic Silver */

    transition: 0.5s;
    font-size: 1.5em;
    outline: none;
    user-select: none;
    cursor: pointer;

    &:hover {
        font-weight: bold;
        opacity: 0.9;
    }
`;

const Sections = ({global, Sections, sectionStyle}) => {
    const [items, setItems] = useState([]);
    const [selected, Selected] = useState("");
    const [loaded, Loaded] = useState(false);
    
    useEffect(() => {
        Selected("")
        Loaded(false)
        
        global.GET('note/sections')
        .then(data => {
            setItems(data)
            //console.log('Sections', data)
            Loaded(true)
            Selected("-1-")
        })
        .catch(error => console.log({ error }))
        /*
        Api.get('note/sections')
            .then(response => {
                //console.log('Sections', response)
                setItems(response.data)
                Loaded(true)
            })
            .catch(err => console.error(err))
*/
    }, [global])

    useEffect(() =>{
        Sections(selected)

        items.forEach(setStyle)

        global.Selected(selected)
        
    }, [selected])

    function onSection(s) {
        let ss = "-"+s.toString()+"-"
        if(selected.length>0 && selected.includes(ss))
            Selected(selected.replace(ss,""))
        else
            Selected(ss+selected) 
        
        //console.log("selected",selected)
        //Sections(selected)
    }

    const setStyle = (item) => {
        let style = sectionStyle(item.Id,5)
        if(selected.includes(`-${item.Id}-`)) {
            style.opacity=1
            style.color="white"
            style.fontWeight="bold"
        }
        return style
    }

    return (

        <Panel>
            { loaded ?

<table>

<tbody>
    {
        items.map((item, index) => (
            <tr key={index}>
        <td>
            <Section style={setStyle(item)}
                onClick={() => onSection(item.Id)}>{item.Name}</Section>
        </td>

            </tr>
        ))
    }
</tbody>
</table>
:
<h5>Items is loading...</h5>
}


        </Panel>
    )
}

export default Sections