import axios from 'axios'

export default axios.create({

  baseURL: `https://api.tech2us.ma`,
  headers : {            
    'Accept':'application/json',
    'Content-Type':'application/json'
  }
  //baseURL: `https://localhost:44375/`

})