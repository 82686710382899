import React, { useLayoutEffect } from 'react'
import {Detail} from '../Note/Detail'
import Comment from '../Comment/Comment'
import { sectionStyle } from './Formating'

import {meSplitter} from './meGlobal'

const NoteControl = ({global, element, onElement, editing, onLoad, search}) => {

    const detail = new meSplitter("NoteSide", "Note", false)

    useLayoutEffect (()=>{  
            detail.selectorAll()       
        },[])
    

return (
    <div className="Sides" id="NoteContainer"
            onMouseDown={(e)=>{detail.mouseDown(e)}} 
            onMouseMove={(e)=>{detail.mouseMove(e)}} 
            onMouseUp={(e)=>{detail.mouseUp(e)}}
        >


        <div className="side hside" id="NoteSideNote" style={{height: '30%'}}>
        <Detail global={global} id={element} LoadNotes={onLoad} onNote={onElement} editing={editing} />
        </div>
        <div className="side hside" id="NoteSideTimer" style={{height: '70%'}}>
            <Comment global={global} note={element} sectionStyle={sectionStyle} />

        </div>
    </div>


     )
}

export default NoteControl
